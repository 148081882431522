import axios from '@/common/axios'
import qs from 'qs'

export function addReagent (data) {
  return axios({
    method: 'post',
    url: '/storage/reagent/add',
    data: qs.stringify(data)
  })
}

export function deleteReagent (id) {
  return axios({
    method: 'delete',
    url: '/storage/reagent/delete/' + id
  })
}

export function updateReagent (data) {
  return axios({
    method: 'put',
    url: '/storage/reagent/update',
    data: qs.stringify(data)
  })
}

export function selectReagentInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/reagent/info/' + id
  })
}

export function selectReagentList (query) {
  return axios({
    method: 'get',
    url: '/storage/reagent/list',
    params: query
  })
}
