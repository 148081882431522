<template>
  <div id="reagent">
    <el-dialog
      :title="reagentFormTitle"
      width="680px"
      :visible.sync="reagentDialogVisible"
      :close-on-click-modal="false"
      @close="reagentDialogClose"
    >
      <el-form
        ref="reagentFormRef"
        :model="reagentForm"
        :rules="reagentFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="试剂名称" prop="reagentName">
              <el-input v-model="reagentForm.reagentName" placeholder="请输入试剂名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制批号" prop="batchNum">
              <el-input v-model="reagentForm.batchNum" placeholder="请输入配制批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制日期" prop="compoundDate">
              <el-date-picker v-model="reagentForm.compoundDate" placeholder="请选择配制日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="浓度" prop="concentration">
              <el-input v-model="reagentForm.concentration" placeholder="请输入浓度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用途" prop="purpose">
              <el-input v-model="reagentForm.purpose" placeholder="请输入用途" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用数量(mL)" prop="collectionQuantity">
              <el-input v-model="reagentForm.collectionQuantity" placeholder="请输入领用数量(mL)" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用人" prop="collector">
              <el-input v-model="reagentForm.collector" placeholder="请输入领用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发放人" prop="provider">
              <el-input v-model="reagentForm.provider" placeholder="请输入发放人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发放日期" prop="provideDate">
              <el-date-picker v-model="reagentForm.provideDate" placeholder="请选择发放日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="reagentForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="reagentDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="reagentFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="试剂名称">
        <el-input v-model="searchForm.reagentName" placeholder="请输入试剂名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="reagentPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="reagentName" label="试剂名称" />
      <el-table-column prop="batchNum" label="配制批号" />
      <el-table-column label="配制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.compoundDate">{{ scope.row.compoundDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="concentration" label="浓度" />
      <el-table-column prop="purpose" label="用途" />
      <el-table-column prop="collectionQuantity" label="领用数量(mL)" />
      <el-table-column prop="collector" label="领用人" />
      <el-table-column prop="provider" label="发放人" />
      <el-table-column label="发放日期">
        <template slot-scope="scope">
          <span v-if="scope.row.provideDate">{{ scope.row.provideDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="reagentPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addReagent, deleteReagent, updateReagent, selectReagentInfo, selectReagentList } from '@/api/storage/reagent'

export default {
  data () {
    return {
      reagentDialogVisible: false,
      reagentFormTitle: '',
      reagentForm: {
        id: '',
        reagentName: '',
        batchNum: '',
        compoundDate: '',
        concentration: '',
        purpose: '',
        collectionQuantity: '',
        collector: '',
        provider: '',
        provideDate: '',
        remarks: ''
      },
      reagentFormRules: {
        reagentName: [{ required: true, message: '试剂名称不能为空', trigger: ['blur', 'change']}]
      },
      reagentPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        reagentName: ''
      }
    }
  },
  created () {
    selectReagentList(this.searchForm).then(res => {
      this.reagentPage = res
    })
  },
  methods: {
    reagentDialogClose () {
      this.$refs.reagentFormRef.resetFields()
    },
    reagentFormSubmit () {
      if (this.reagentFormTitle === '试液发放详情') {
        this.reagentDialogVisible = false
        return
      }
      this.$refs.reagentFormRef.validate(async valid => {
        if (valid) {
          if (this.reagentFormTitle === '新增试液发放') {
            await addReagent(this.reagentForm)
          } else if (this.reagentFormTitle === '修改试液发放') {
            await updateReagent(this.reagentForm)
          }
          this.reagentPage = await selectReagentList(this.searchForm)
          this.reagentDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.reagentFormTitle = '新增试液发放'
      this.reagentDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteReagent(row.id)
        if (this.reagentPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.reagentPage = await selectReagentList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.reagentFormTitle = '修改试液发放'
      this.reagentDialogVisible = true
      this.selectReagentInfoById(row.id)
    },
    handleInfo (index, row) {
      this.reagentFormTitle = '试液发放详情'
      this.reagentDialogVisible = true
      this.selectReagentInfoById(row.id)
    },
    selectReagentInfoById (id) {
      selectReagentInfo(id).then(res => {
        this.reagentForm.id = res.id
        this.reagentForm.reagentName = res.reagentName
        this.reagentForm.batchNum = res.batchNum
        this.reagentForm.compoundDate = res.compoundDate
        this.reagentForm.concentration = res.concentration
        this.reagentForm.purpose = res.purpose
        this.reagentForm.collectionQuantity = res.collectionQuantity
        this.reagentForm.collector = res.collector
        this.reagentForm.provider = res.provider
        this.reagentForm.provideDate = res.provideDate
        this.reagentForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectReagentList(this.searchForm).then(res => {
        this.reagentPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectReagentList(this.searchForm).then(res => {
        this.reagentPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectReagentList(this.searchForm).then(res => {
        this.reagentPage = res
      })
    }
  }
}
</script>

<style>
</style>
